.meeting-container {
  margin: 3ch;
  padding: 2ch;
  background-color: #ececec;
  border-radius: 1ch;
}

.acceptanceButtons * {
  color: white;
}

.acceptanceButtons span {
  display: flex;
  align-items: center;
}

.descriptionContainer * {
  overflow-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .acceptanceButtons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
