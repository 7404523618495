.country-flag {
  width: 16px;
  border-radius: 10px;
  margin-right: 5px;
  position: relative;
  top: 3px;
  margin-right: 2ch;
}

.language-selector {
  right: 0;
  margin: 2ch;
  border-radius: 1ch;
  background-color: whitesmoke;
  color: black;
  position: relative;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-btn {
  margin: 1ch;
  width: fit-content;
  display: flex;
  justify-content: space-between;
}

.dropdown-btn > * {
  margin: 1ch;
}

.globe {
  width: 2ch;
}

.dropdown-menu {
  position: absolute;
  background-color: whitesmoke;
  border-radius: 1ch;
  margin-top: 1ch;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.caret {
  width: 1.5ch;
}

.lang-option {
  padding: 2ch;
  width: 15ch;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .language-selector {
    display: flex;
  }
}
