.djLogo {
  height: 8vh;
  pointer-events: none;
  padding: 2ch;
}

header {
  background-color: var(--secondary);
  display: flex;
  gap: 2ch;
  color: white;
  padding: 1ch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

#dj_header {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 700px) {
  header {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  #dj_header > h1 {
    font-size: 1.5rem;
  }
}
