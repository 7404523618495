body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --text-color: black;
  --secondary: #5236ab;
  --primary: #200a58;
  --primary-error-color: #d2062b;
  --primary-warning-color: #ffac25;
  --primary-success-color: #46a35e;
  width: 100%;
}

#root {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.destructive {
  background-color: var(--primary-error-color);
}

.cancel {
  background-color: #5e5e5e;
  color: white;
}

.accept {
  background-color: var(--primary-success-color);
}

.disabled {
  background-color: #d4d4d4;
  color: white;
  cursor: not-allowed;
}

button {
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  button {
    padding: 2ch;
    font-size: large;
  }
}

svg {
  margin-right: 1ch;
  margin-left: 1ch;
}
